import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import PrimaryMenuHeading from 'components/Header/PrimaryMenuHeading';
import ThemeButton from 'components/buttons/ThemeButton';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    maxWidth: 'var(--width-overlays)',
    display: 'flex',
    flexDirection: 'column',
    height: `calc(var(--vh) - var(--height-header) - 8px)`,
    top: `calc(var(--height-header) + 4px)`,
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '8px',

    [media.min['desktop.sm']]: {
        top: '4px',
        padding: '24px',
    },
});

const SiteSelectorWrapper = styled('div', {
    position: 'absolute',
    left: '12px',
    right: '12px',
    bottom: '8px',
    alignItems: 'center',
    zIndex: 1,
    borderTop: '1px solid var(--color-border-navigation)',
    padding: '12px 0',
});

const SiteSelectorOverlay = ({ alternateHrefs = [], isSiteSelectorOpen }) => {
    const [css] = useStyletron();
    const { siteSelectorLinks } = useSiteSelectorQuery();
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);
    const isSiteSelectorActive = !!isSiteSelectorOpen;

    const resetNavigation = () => {
        overlay.close('site-selector');
    };

    return (
        <Wrapper>
            <PrimaryMenuHeading
                isSiteSelectorOpen={isSiteSelectorActive}
                resetNavigation={resetNavigation}
                text={fm('Menu')}
            />
            <SiteSelector isOverlay alternateHrefs={alternateHrefs} />
            <SiteSelectorWrapper>
                <ThemeButton
                    $style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '8px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    theme="defaultsecondary"
                    onClick={() => resetNavigation()}
                >
                    <div
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        })}
                    >
                        <div
                            className={css({
                                width: '24px',
                                height: '16px',
                                marginRight: '8px',
                                borderRadius: '2px',
                            })}
                        >
                            <Image sizes={['24px']} src={{ url: currentSite?.icon, width: [24, 48, 72] }} />
                        </div>
                        <Paragraph fontKeys="Miscellaneous/16_100_-1">{currentSite?.label}</Paragraph>
                    </div>
                    <StateIcon
                        state={isSiteSelectorOpen ? 'close' : 'open'}
                        width="24px"
                        onClick={() => resetNavigation()}
                    />
                </ThemeButton>
            </SiteSelectorWrapper>
        </Wrapper>
    );
};

SiteSelectorOverlay.propTypes = {
    alternateHrefs: PropTypes.array,
    isSiteSelectorOpen: PropTypes.bool,
};

export default SiteSelectorOverlay;
